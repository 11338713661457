export default class Room {

  constructor() {
   }

   async check_vpn(){
    //  await $.getJSON("https://ipgeolocation.abstractapi.com/v1/?api_key=320069048c744fc3a575f5deb42179a1", function(data) {
    //    if (data.security["is_vpn"]){
    //      console.error("USING VPN")
    //      Rollbar.error("USING_VPN")
    //    } else {
    //      console.log("not using vpn")
    //    }
    // })
   }


   check_browser(){
       var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveBlob || !!navigator.msSaveOrOpenBlob);
       var isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;
       isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ('netscape' in window) && / rv:/.test(navigator.userAgent);
       isChrome = (!isOpera && !isEdge && !!navigator.webkitGetUserMedia) || this.isElectron() || navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1;
       isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
       if (isSafari && !isChrome && navigator.userAgent.indexOf('CriOS') !== -1) {
           isSafari = false;
           isChrome = true;
       }
      // if (Rosom.is_iOS()) { $("#use_browser").show() }
   }


  is_iOS(){
    // return true;
    return  ['iPad Simulator','iPhone Simulator','iPod Simulator','iPad','iPhone','iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  isElectron() {
     // Renderer process
     if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
         return true;
     }

     // Main process
     if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
         return true;
     }

     // Detect the user agent when the `nodeIntegration` option is set to true
     if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
         return true;
     }

     return false;
   }



  start_recording_timer(){
     var new_time = Math.abs(Math.round((Date.parse($("#started_at").attr("data-timestamp")) - Date.now())/1000 + 5)).toString().toHHMMSS()
     $("#timer").text(new_time)
     window.recording_interval = setInterval(function(){
       var new_time = Math.abs(Math.round((Date.parse($("#started_at").attr("data-timestamp")) - Date.now())/1000 + 5)).toString().toHHMMSS()
       $("#timer").text(new_time)
     }, 1000);
   }

   stopping_recording(){
     clearInterval(window.local_checker);
     clearInterval(window.recording_interval);
   }

   // this shoud work for guests and hosts
  stop_showing_now_recording(){
    this.stopping_recording()
    // $("#room_panel .devices").slideDown(200);
    // $("#room_panel .toggle_devices").removeClass("fa-chevron-down").addClass("fa-chevron-up")
    $(".remote_camera_settings").prop('disabled', false);
    $(".remote_microphone_settings").prop('disabled', false);
    $(".enable_echo_button, .disable_echo_button").prop('disabled', false);
    $(".user_reduce_noise").prop('disabled', false);
    $(".user_remove_echo").prop('disabled', false);
    $("#video_setting").prop('disabled', false);
    $("#microphone_setting").prop('disabled', false);
    $(".disable_echo_cancellation, .enable_echo_cancellation").prop('disabled', false);
    $("#toggle_video").removeClass('disabled');
    $("#toggle_mic").removeClass('disabled');
    $("#max_quality_dropdown").prop('disabled', false);
    $("#frame_rate_dropdown").prop('disabled', false);
    $(".video_type_label").removeClass('opacity-40');
    $(".echo_row").css("opacity", "1");
    $(".settings_cant_be_changed").hide()

     console.log("stop showing recording")
     if (window.uploading) {
       window.uploading = false;
       $("#still_uploading_local").show()
       $("#uploading_complete").show()
       var new_button = `<i class="fas fa-circle p-2 text-red-600 bg-gray-200 dark:bg-gray-800 rounded-full"></i>
          <span class="mx-1 dark:text-gray-600">Waiting on host <span class="hidden sm:inline">to record</span></span>`
       $("#guest_start_recording_button").removeClass()
       $("#guest_start_recording_button").html(new_button).addClass("px-2 py-2 inline text-sm rounded mb-5")
       LocalStream.stopRecording()
     }
     window.uploading = false;

     if (screenrecorder){
       if (screenrecorder.state == "recording"){
         screenrecorder.stop();
         //sleep
         (async() => {
           await new Promise(r => setTimeout(r, 2000));
         })();
       }
       screenrecorder = undefined;
     }
   }

  check_force_stop_recording(){
     if ($("#stop_recording_button").length > 0) {
       var data = new FormData();
       data.append("_method", "patch");
       var param = document.querySelector("meta[name=csrf-param]").getAttribute("content");
       var token = document.querySelector("meta[name=csrf-token]").getAttribute("content");
       data.append(param, token);
       navigator.sendBeacon($("#stop_recording_button").attr("href"), data);
       console.log("STOPPING")
     } else {
       console.log("-- not recording")
     }
   }

  show_now_recording(show_already_recording = false) {
      console.log("show recording.....")
      LocalStream.record();
      $("#room_panel .devices").slideUp(200);
      $("#room_panel .toggle_devices").removeClass("fa-chevron-up").addClass("fa-chevron-down")

      if (producer_role == false) {
        $(".remote_camera_settings").prop('disabled', true);
        $(".remote_microphone_settings").prop('disabled', true);
        $(".enable_echo_button, .disable_echo_button").prop('disabled', true);
        $(".user_reduce_noise").prop('disabled', true);
        $(".user_remove_echo").prop('disabled', true);
        $("#video_setting").prop('disabled', true);
        $("#microphone_setting").prop('disabled', true);
        $(".disable_echo_cancellation, .enable_echo_cancellation").prop('disabled', true);
        $("#max_quality_dropdown").prop('disabled', true);
        $("#frame_rate_dropdown").prop('disabled', true);
        $(".video_type_label").addClass("opacity-40")

        $(".echo_row").css("opacity", "0.5");
        $("#toggle_video").addClass('disabled');
        $("#toggle_mic").addClass('disabled');
        $(".settings_cant_be_changed").show()
      }

     window.uploading = true
     var blob_index = 1;
     var new_button = `<span class="mx-1 inline-block" style="text-align: left;width: 151px;margin-top: -4px;padding-left:4px">Recording - <span id="timer" data-time="0" >00:00:00</span></span>`
     $("#guest_start_recording_button").html(new_button)
     $("#guest_start_recording_button").removeClass()
     $("#guest_start_recording_button").addClass("px-2 py-2 text-sm rounded mb-5 dark:text-gray-200 inline-block -mt-1 dark:bg-red-800 bg-red-600 text-white")

     var recording_id = parseInt($("[data-recording-id]").attr("data-recording-id"))
     db.recordings.put({ recording_id: recording_id, attendee_id: $("#attendee_id").val() }).then (function(){
       console.log(`Added recording id ${recording_id} into the local db`)
     }).catch(function(error) {
       if (error.message.includes("QuotaExceededError")){
         Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=nospace`, type: "patch"})
         alert("You have no space avaialable on your computer for local recordings. ERROR SAVING BLOB: " + error);
       } else{
         console.error ("ERROR SAVING BLOB2: " + error);
         Rollbar.error ("ERROR SAVING BLOB (show_now_recording): " + error);
         alert("You may need to use another browser. We were unable to save files locally. ERROR SAVING BLOB: " + error);
       }
     });
     if (show_already_recording){
       this.start_recording_timer()
     }else {
       CountdownTimer.start_countdown_timer()
     }

     // already running so dont call again
     if (local_db.running != true) {
       local_db.check_for_recordings_to_upload()
     }

     // // update recording if camera is off
     // if ($("#video_setting").val() == "off") {
     //   setTimeout(function(){
     //       Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/camera_off.js?recording_id=${recording_id}`, type: "patch"})
     //   }, 3000);
     // }

     $("#still_uploading_local, #uploading_past_recordings, #uploading_complete").hide()
   }


   async startScreenCall() {
      if(!navigator.mediaDevices.getDisplayMedia) {
          var error = 'Your browser does NOT supports getDisplayMedia API.';
          alert(error);
      } else {
         await navigator.mediaDevices.getDisplayMedia({audio: true, video: true})
         .then(async function(camera) {
           if (!screenClient){
             screenClient = await AgoraRTC.createClient();
             await screenClient.join(options.appid, options.channel, options.share_screen_token, options.share_uid)
              .then(async uid => {
                  console.log("connected to screentrack channel")
               }).catch(error => {
                Rails.ajax({url: `/${subscription_id}/attendee/${options.share_uid}/remove_share_screen_attendee.js`, type: "delete"})
                console.error("screen_share_error1 "+error.message)
                alert("Unable to share your screen. \n\nYou can always contact our support for help by emailing support@iris.fm. \n\n" + error.message)
              })
           }
           screenTracks.videoTrack = undefined
           screenTracks.audioTrack = undefined

           screenstream = camera;
           try {
             screenTracks.audioTrack = AgoraRTC.createCustomAudioTrack({mediaStreamTrack: camera.getAudioTracks()[0]});
           } catch (error){
             console.log(error)
             console.log("Cant create audio track for screen sharing")
           }
           try {
             // set constraints for smaller resolution?
             screenTracks.videoTrack = AgoraRTC.createCustomVideoTrack({mediaStreamTrack: camera.getVideoTracks()[0], bitrateMax: 1200, bitrateMin: 600});
               } catch (error){
                 console.log(error)
                 console.log("Cant create video track for screen sharing")
               }
         }).catch(async function(error) {
          if (error.message.includes("Permission denied")){
            alert(`Sharing screen error: ${error} \n
 Make sure you have allowed your browser to screenshare.
 System Preferences -> Security & Privacy -> Privacy -> Screen Recording`)
}else if (error.message.includes("The request is not allowed by the user agent")){ //  NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
  alert(`Sharing screen error: ${error} \n
Make sure you have allowed your browser to screenshare.
System Preferences -> Security & Privacy -> Privacy -> Screen Recording`)
}else if (error.message.includes("must be called from a user gesture handler")){ //  invalidAccessError: getDisplayMedia must be called from a user gesture handler
  alert(`Sharing screen error: ${error} \n
You try to enable the experimental feature "Enable MDNS Ice Candidates" in Safari settings, advanced, experimental features`)
} else {
  console.error("Sharing screen error:", error)
  Rollbar.error("Sharing_screen_error")
}

         });

      if (screenTracks.videoTrack || screenTracks.audioTrack){
        if (screenTracks.videoTrack){
          console.log("PUBLISH SCREEN VIDEO TRACK")
          screenTracks.videoTrack.on("track-ended", this.stopShareScreen)
          await screenClient.publish(screenTracks.videoTrack)
          .then(async test => {
              console.log("published video track")
           }).catch(error => { console.log(error)} );
        }
        if (screenTracks.audioTrack){ await screenClient.publish(screenTracks.audioTrack) }
        $("#share_screen_button").hide()
        $("#stop_share_screen_button").show()
        var recording_id = $("#recording_id").attr("data-recording-id");
        if (recording_id.length){
          Rails.ajax({url: `/recordings/${recording_id}/update_layout.js?share_uid=${options.share_uid}`, type: "patch"})
          this.record_screen_share();
        }
        return screenTracks;
      } else{
        Rails.ajax({url: `/${subscription_id}/attendee/${options.share_uid}/remove_share_screen_attendee.js`, type: "delete"})
      }
    }

   }

   record_screen_share(){

     if (screenrecorder) {
       if (screenrecorder.state == "recording"){ screenrecorder.stop() }
       screenrecorder = undefined;
     }

     screenrecorder = new MediaRecorder(screenstream);
     screenrecorder.ondataavailable = function(e) {
       Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/file_saved.js`, type: "patch"})

       if (!e.data || !e.data.size || e.data.size < 100){
          Room.show_error_message("Your browser tried to save a recording without any data. Please try a different browser like Google Chrome or else contact support at support@iris.fm.")
          if (!e.data || !e.data.size){
            console.error("NO DATA ondataavailable _record_screen_share")
            setTimeout(function(){
              Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/error.js?error=zerofile`, type: "patch"})
            }, 1000);
          } else {
            console.error("Too small DATA ondataavailable: "  + e.data.size)
            setTimeout(function(){
              Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/error.js?error=toosmallfile` + e.data.size, type: "patch"})
            }, 1000);
          }
       }else {
         var current_recording_id = parseInt($("[data-recording-id]").attr("data-recording-id"))
         LocalStream.save_screen_blob(e.data, screen_blob_index, current_recording_id);
         screen_blob_index++;
       }
     }
     screenrecorder.start(15000)
     Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/recording_started.js`, type: "patch"})
  }


  async stopShareScreen(){
    Rails.ajax({url: `/${subscription_id}/attendee/${options.share_uid}/remove_share_screen_attendee.js`, type: "delete"})
    for (var trackName in screenTracks) {
      var track = screenTracks[trackName];
      if(track) {
        track.stop();
        track.close();
        screenTracks[trackName] = undefined;
      }
    }
    if (screenClient){
      await screenClient.leave();
      screenClient = undefined
    }
    $("#share_screen_button").show()
    $("#stop_share_screen_button").hide()

    var attendee_id = $("#attendee_id").val();
    var recording_id = $("#recording_id").attr("data-recording-id")
    if (recording_id.length){
      Rails.ajax({url: `/recordings/${recording_id}/update_layout.js?attendee_id=${attendee_id}`, type: "patch"})
    }
    if (screenrecorder){
      if (screenrecorder.state != 'inactive'){
        screenrecorder.stop();
      }
      screenrecorder = undefined;
      Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/recording_stopped.js`, type: "patch"})
    }
    FixVideos.fix_resolution();
  }


   host_error_message(message, room_attendee_id){
     if ($("#is_host").length > 0 && $("#attendee_id").val() != room_attendee_id){
       this.show_error_message(message)
     }
   }

   show_error_message(message){
     $("#error_modal_message").html(message)
     $("#error_modal").show()
   }

   async toggleVideo(){
     // if ($("#toggle_video").hasClass('disabled')){
     //   return;
     // }

     var attendee_id = $("#attendee_id").val()

     if (event){ event.preventDefault() }
     // $("#video-btn i").toggleClass('fa-video').toggleClass('fa-video-slash'); // toggle the video icon
     if (currentCam && !$("#video-btn i").hasClass('fa-video')) {
       //enabled
       // $(`.local-player `).addClass("player").show()
       // FixVideos.fix_resolution()

       if (producer_role && $("#mic-btn span").text() == "Unmute" ){
         $(`.local-player`).addClass("player").show()
       }


       $(`#player-${options.uid} .video_camera_off_icon`).remove()
       $("#video-btn i").removeClass("dark:bg-red-900 bg-red-200 text-red-600")
       $("#test_video_setting, #video_setting").css("opacity", "1")
       $("#video-btn .fa-video-slash").removeClass("fa-video-slash").addClass("fa-video")
       $(`#player-${options.uid} .fa-video-slash`).removeClass("fa-video-slash").addClass("fa-video")
       $(`#user-row-${options.uid} .fa-video-slash`).hide()
       $(`#user-row-${options.uid} .fa-video`).show()
       $(`#toggle_video .fa-video-slash, #join_modal .fa-video-slash`).removeClass("fa-video-slash").addClass("fa-video")
       $(".settings_option .fa-video, #join_modal .fa-video").removeClass("text-red-500").addClass("text-indigo-500")
       $("#video-btn span").text("Camera Off")
       // await LocalStream.update()

       if ($("#subscription_id").attr("data-max-width") != '0'){
         if (local_media_stream) {
           if (local_media_stream.getVideoTracks().length > 0){
             local_media_stream.getVideoTracks()[0].enabled = true
           } else {
             await LocalStream.update()
           }
         } else {
           await LocalStream.update()
         }
       }

       if (!localTracks.videoTrack){
         await AgoraRTC.createCameraVideoTrack({cameraId: currentCam.deviceId})
         .then(async track => {
           localTracks.videoTrack = track;
           await localTracks.videoTrack.setEnabled(true);
           if (client.uid) {
             await localTracks.videoTrack.play(`player-${options.uid}`)
             await client.publish(localTracks.videoTrack)
           } else {
             if ($("#pre-local-player").length > 0){ await localTracks.videoTrack.play(`pre-local-player`) }
           }
           Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/camera_on.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
         })
         .catch(error => {
           // alert(error.message)
         })
       } else {
         if (!localTracks.videoTrack._enabled) {
           await localTracks.videoTrack.setEnabled(true);
           if (client.uid) {
             await localTracks.videoTrack.play(`player-${options.uid}`)
             await client.publish(localTracks.videoTrack)
           }else {
             if ($("#pre-local-player").length > 0){ await localTracks.videoTrack.play(`pre-local-player`) }
           }
           Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/camera_on.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
         }
       }

       if (localTracks.videoTrack && !this.is_iOS()){
         await localTracks.videoTrack.setDevice(currentCam.deviceId);
       }
       FixVideos.fix_resolution()
       if (currentCam) { $(`#test_video_setting, #video_setting, #user-row-${options.uid} .remote_camera_settings`).val(currentCam.label) }
       $(`#player-${options.uid}`).show()
       $(`#user-name-${options.uid}`).show()
        // localTracks.videoTrack.play()
     } else {
       //disabled
       $("#video-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
       $("#test_video_setting, #video_setting").css("opacity", "0.5")
       // $(".fa-video").addClass("fa-video-slash").removeClass("fa-video")

       $("#video-btn .fa-video").removeClass("fa-video").addClass("fa-video-slash")
       $(`#player-${options.uid} .fa-video`).removeClass("fa-video").addClass("fa-video-slash")
       $(`#toggle_video .fa-video`).removeClass("fa-video").addClass("fa-video-slash")

       $(" #join_modal .fa-video").addClass("fa-video-slash").removeClass("fa-video")
       $(".settings_option .fa-video-slash, #join_modal .fa-video-slash").addClass("text-red-500").removeClass("text-indigo-500")

       $("#video-btn span").text("Show Camera")
       $(`#test_video_setting, #video_setting, #user-row-${options.uid} .remote_camera_settings`).val("off")
       $(`#user-row-${options.uid} .fa-video-slash`).show()
       $(`#user-row-${options.uid} .fa-video`).hide()
       // await LocalStream.update()
       if (local_media_stream) {
         if (local_media_stream.getVideoTracks().length > 0){
           local_media_stream.getVideoTracks()[0].enabled = false
         }
       }

       if (localTracks.videoTrack) {
         // if (client.uid){ await client.unpublish(localTracks.videoTrack) }
         await localTracks.videoTrack.setEnabled(false)
       }

       Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/camera_off.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
       PlayerOptions.addCameraOffIcon($(`#player-${options.uid}`))
       // $(`#user-name-${options.uid}`).hide()
       // $(`#player-${options.uid}`).hide()


       //hide video in case
       // $(`#player-${options.uid} .agora_video_player`).parent().remove()
       // localTracks.videoTrack.stop()
       //remove player for self if producer and all off
       if (producer_role && $("#mic-btn span").text() == "Unmute" ){
         $(`.local-player`).removeClass("player").hide()
       }
       //fix for firefox
       if (localTracks.videoTrack){
         localTracks.videoTrack.stop();
       }
       FixVideos.fix_resolution()

     }


   }

   async toggleMic() {
     // if ($("#toggle_mic").hasClass('disabled')){
     //   return;
     // }
     var attendee_id = $("#attendee_id").val()
     if (event){ event.preventDefault() }

     var attendee_id = $("#attendee_id").val()
     // $("#mic-btn i").toggleClass('fa-microphone').toggleClass('fa-microphone-slash'); // toggle the mic icon
     if (currentMic && !$("#mic-btn i").hasClass('fa-microphone')) {

       if (producer_role && $("#mic-btn span").text() == "Unmute" ){
         $(`.local-player`).addClass("player").show()
         FixVideos.fix_resolution()
       }

       $("#mic-btn i").removeClass("dark:bg-red-900 bg-red-200 text-red-600")
       $("#test_microphone_setting, #microphone_setting").css("opacity", "1")
       $(".settings_option .fa-microphone-slash, #join_modal .fa-microphone-slash").removeClass("text-red-500").addClass("text-indigo-500")
       $("#mic-btn i, .settings_option .fa-microphone-slash, #join_modal .fa-microphone-slash").removeClass("fa-microphone-slash").addClass("fa-microphone")
       $("#mic-btn span").text("mute")
       if (!localTracks.audioTrack){
         await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config)
         .then(track => {
           localTracks.audioTrack = track;
           if (currentSpeaker){
             // localTracks.audioTrack.setPlaybackDevice(currentSpeaker.deviceId)
             // .catch(error => {
             //   console.error("could not setPlaybackDevice 2" + error.message)
             //   // alert(error.message)
             // })
           }
         })
         .catch(error => {
           console.error("couldnt_create_mic: " + error.message);
           alert(error.message)
         })
       }
       if (localTracks.audioTrack){
         if (!localTracks.audioTrack._enabled){
           await localTracks.audioTrack.setEnabled(true)
           if (client.uid){ await client.publish(localTracks.audioTrack) }
         }
         if (!this.is_iOS()){
          await localTracks.audioTrack.setDevice(currentMic.deviceId);
         }
       }

       if (local_media_stream) {
         if (local_media_stream.getAudioTracks().length > 0) {
           local_media_stream.getAudioTracks()[0].enabled = true
         } else {
           await LocalStream.update()
         }
       } else {
         await LocalStream.update()
       }
       if (currentMic) { $(`#test_microphone_setting, #microphone_setting, #user-row-${options.uid} .remote_microphone_settings`).val(currentMic.label) }
       Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/unmute.js`, type: "patch"})
     } else {
       //disabled
       $("#test_microphone_setting, #microphone_setting").css("opacity", "0.5")
       $("#mic-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
       $(".settings_option .fa-microphone, #join_modal .fa-microphone").addClass("text-red-500").removeClass("text-indigo-500")
       $("#mic-btn i, .settings_option .fa-microphone, #join_modal .fa-microphone").addClass("fa-microphone-slash").removeClass("fa-microphone")
       $("#mic-btn span").text("Unmute")
       $(`#test_microphone_setting, #microphone_setting, #user-row-${options.uid} .remote_microphone_settings`).val("off")
       if (localTracks.audioTrack) {
         // if (client.uid){ await client.unpublish(localTracks.audioTrack) }
         await localTracks.audioTrack.setEnabled(false)
       }
       // await LocalStream.update()

       if (local_media_stream) {
         if (local_media_stream.getAudioTracks().length > 0){
           local_media_stream.getAudioTracks()[0].enabled = false
         }
       }
       Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/mute.js`, type: "patch"})

       if (producer_role && $("#video-btn span").text() == "Show Camera" ){
         $(`.local-player`).removeClass("player").hide()
       }
       //fix for firefox
       // localTracks.videoTrack.stop()
       FixVideos.fix_resolution()
     }
   }

   async enableEchoCancellation(id = options.uid){
     options.echo_cancellation = true;
     $(".disable_echo_button").show()
     $(".enable_echo_button").hide()

     $(`#user-row-${id} .user_remove_echo`).addClass("bg-indigo-500 dark:bg-indigo-700")
     $(`#user-row-${id} .user_remove_echo span`).addClass("translate-x-3")

     if (!this.is_iOS()){
       await LocalStream.update()
     }
     if (localTracks.audioTrack) {
       if (client){
         if (client.uid){ await client.unpublish([localTracks.audioTrack]) }
       }
     }
     // check again in case unpublish took a while
     if (localTracks.audioTrack){
       await localTracks.audioTrack.stop();
       await localTracks.audioTrack.close();
       localTracks.audioTrack = undefined;
     }
     localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config);
     if (client){
       if (client.uid){ await client.publish([localTracks.audioTrack]) }
     }

     if (this.is_iOS()){
      await LocalStream.update()
     }

     console.log("ALL UPDATED")
   }

   async disableEchoCancellation(id = options.uid){
     options.echo_cancellation = false;
     $(".disable_echo_button").hide()
     $(".enable_echo_button").show()

     $(`#user-row-${id} .user_remove_echo`).removeClass("bg-indigo-500 dark:bg-indigo-700")
     $(`#user-row-${id} .user_remove_echo span`).removeClass("translate-x-3")
     // $(".tooltip").hide().delay(2000).show()
     // $(".tooltip_message").show().delay(2000).fadeOut()

     // $(".enable_echo_cancellation").removeClass("dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 bg-indigo-100 border-indigo-300 text-indigo-600").addClass("dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-700 dark:hover:text-gray-500 hover:text-gray-700 hover:border-gray-500")
     // $(".disable_echo_cancellation").addClass("dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 bg-indigo-100 border-indigo-300 text-indigo-600").removeClass("dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-700 dark:hover:text-gray-500 hover:text-gray-700 hover:border-gray-500 ")

       if (!this.is_iOS()){
        await LocalStream.update()
       }

       if (localTracks.audioTrack) {
         if (client){
            if (client.uid){ await client.unpublish([localTracks.audioTrack]) }
          }
       }

       if (localTracks.audioTrack) {
         await localTracks.audioTrack.stop();
         await localTracks.audioTrack.close();
         localTracks.audioTrack = undefined;
      }

     localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config);
     if (client){
       if (client.uid){ await client.publish([localTracks.audioTrack]) }
     }

     if (this.is_iOS()){
      await LocalStream.update()
     }
   }

   async showLiveTranscript(message){
     $("#live_transcript").text(message)

     if (Transcribe.running != true){  Transcribe.start() }
   }

   async enableNoiseReduction(id = options.uid){
     options.noise_reduction = true;
     // $(".disable_echo_button").show()
     // $(".enable_echo_button").hide()
     $(`#user-row-${id} .user_reduce_noise`).addClass("bg-indigo-500 dark:bg-indigo-700")
     $(`#user-row-${id} .user_reduce_noise span`).addClass("translate-x-3")

     if (!this.is_iOS()){
       await LocalStream.update()
     }
     if (localTracks.audioTrack) {
       if (client.uid){ await client.unpublish([localTracks.audioTrack]) }
     }
     // check again in case unpublish took a while
     if (localTracks.audioTrack){
       await localTracks.audioTrack.stop();
       await localTracks.audioTrack.close();
       localTracks.audioTrack = undefined;
     }
     localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config);
     if (client.uid){ await client.publish([localTracks.audioTrack]) }

     if (this.is_iOS()){
      await LocalStream.update()
     }
   }

   async disableNoiseReduction(id = options.uid){
     options.noise_reduction = false;
     $(`#user-row-${id} .user_reduce_noise`).removeClass("bg-indigo-500 dark:bg-indigo-700")
     $(`#user-row-${id} .user_reduce_noise span`).removeClass("translate-x-3")
     // $(".disable_echo_button").hide()
     // $(".enable_echo_button").show()
     // $(".tooltip").hide().delay(2000).show()
     // $(".tooltip_message").show().delay(2000).fadeOut()

     // $(".enable_echo_cancellation").removeClass("dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 bg-indigo-100 border-indigo-300 text-indigo-600").addClass("dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-700 dark:hover:text-gray-500 hover:text-gray-700 hover:border-gray-500")
     // $(".disable_echo_cancellation").addClass("dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 bg-indigo-100 border-indigo-300 text-indigo-600").removeClass("dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-700 dark:hover:text-gray-500 hover:text-gray-700 hover:border-gray-500 ")

       if (!this.is_iOS()){
        await LocalStream.update()
       }

       if (localTracks.audioTrack) {
        if (client.uid){ await client.unpublish([localTracks.audioTrack]) }
       }

       if (localTracks.audioTrack) {
         await localTracks.audioTrack.stop();
         await localTracks.audioTrack.close();
         localTracks.audioTrack = undefined;
      }

     localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config);
     if (client.uid){ await client.publish([localTracks.audioTrack]) }

     if (this.is_iOS()){
      await LocalStream.update()
     }
   }

   // check_connection(){
   //   if (client.connectionState == "CONNECTING"){
   //     console.loge("Still trying to connect to room")
   //     setTimeout(function(){ Room.check_connection() }, 5000);
   //   }
   //   else if (client.connectionState != "CONNECTED"){
   //     console.log("NOT CONNECTED TRYING CLOUD PROXY")
   //     // Rollbar.error("USE_CLOUD_PROXY");
   //     client.leave()
   //     client.startProxyServer(3);
   //     this.join_channel()
   //   } else {
   //     console.log("connected dont use proxy")
   //   }
   // }
   async join_channel(){

     if (producer_role){
       if ($("#mic-btn i").hasClass('fa-microphone')){
         await this.toggleMic()
       }
       if ($("#video-btn i").hasClass('fa-video')){
         await this.toggleVideo()
       }
     }


     await client.join(options.appid, options.channel, options.token, options.uid)
      .then(async (uid) =>  {
        console.log("connected to video channel")
        user_joined_room = true;
        options.uid = uid;

        // if (client._isDualStreamEnabled == false){
        //   client.enableDualStream().then(() => {
        //      console.log("Enable dual stream success!")
        //   }).catch(err => {
        //      console.error("enableDualStream:", err)
        //      // Rollbar.error("enableDualStream", err);
        //   });
        // }

        if (localTracks.videoTrack){
          if (localTracks.videoTrack._enabled){
            await client.publish([localTracks.videoTrack]);
            $(`#user-name-${options.uid}`).show()
          }else { console.log("dont push cam - not enabled") }
        }else { console.log("dont push cam") }

        if (localTracks.audioTrack){
          if (localTracks.audioTrack._enabled){
            await client.publish([localTracks.audioTrack])
          }else { console.log("dont push mic - not enabled") }
        }else { console.log("dont push mic") }

      })
      .catch(error => {
        // if (client._cloudProxyServerMode == 'disabled'){
        //   Rollbar.error("try_using_proxy3");
        //   console.log("didnt connect to channel without proxy. will try with proxy 3 now")
        //   client.leave()
        //   client.startProxyServer(3);
        //   this.join_channel()
        // } else if (client._cloudProxyServerMode == "proxy3"){
        //   Rollbar.error("try_using_proxy4");
        //   console.log("didnt connect to channel without proxy. will try with proxy 4 now")
        //   client.leave()
        //   client.startProxyServer(4);
        //   this.join_channel()
        // }else {

        if (!error.message.includes("Client already in connecting/connected state")){
          console.error("agora_join_failure4 "+error.message)
          Rollbar.error("agora_join_failure4", error.message, options.uid, client._cloudProxyServerMode);
          alert("Unable to join the channel. You can try refreshing the webpage, using a different browser, and connecting to a different wifi network. \n\nIf this continues contact our support by emailing support@iris.fm. \n\n" + error.message)
        }
        // }
      })
   }


}
